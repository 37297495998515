import {Link} from 'gatsby'
import React from 'react'
import Layout from '../components/layout/index'
import SEO from '../components/seo'
import {rhythm } from '../utils/typography'
import ProjectCard from "../components/project/project-card";

const projectsPageStyle = {
  headerStyle: {textAlign: 'center'},
  titleStyle: {
    marginTop: rhythm(1),
    marginBottom: 0,
    fontFamily: `Dosis`,
    fontWeight: 200,
  },
  projectsContainerStyle: {
    marginTop: rhythm(1),
    marginBottom: 0
  }
}


const ProjectsPage = ({_, location}) => {
  const {
    headerStyle, 
    titleStyle, 
    projectsContainerStyle
  } = projectsPageStyle
  const title = 'My hobby projects'
  return (
    <Layout location={location}>
      <SEO
        title={title}
        description={title}
      />
      <article>
        <header style={headerStyle}>
          <h1 style={titleStyle}>
            <span role='img' aria-label='projects'>     
              👨‍💻    
            </span>
            {title}        
          </h1>
        </header>
        <div style={projectsContainerStyle}>
          <ProjectCard 
            title="uscheme" 
            description={`μscheme (micro Scheme) is a toy interpreter for the Scheme programming language, 
            implementing a relatively (large) subset of R5RS language specification. The interpreter is written in Haskell 
            and Scheme is partially bootstrapped.`}
            githubUrl="https://github.com/gboduljak/uscheme"/>
          <ProjectCard 
            title="seam carving" 
            description={`I have implemented the simplified version of the seam carving - an algorithm developed by S.Avidan and A.Shamir. 
            Seam carving, known also as liquid rescaling, is a dynamic programming algorithm for 'content-aware' image resizing. 
            The main idea is to resize an image by removing only the least noticeable pixels. I have also made a small web app used to generate interesting results.`}
            githubUrl="https://github.com/gboduljak/seam-carving"/>
          <ProjectCard 
            title="COOL compiler"
            description={`COOL (Classroom Object Oriented Language) is a programming language resembling many modern programming languages, including features such as objects, automatic memory management, strict static typing and simple reflection. 
            This project is a repository of coursework project implementations resulting in a fully working COOL compiler. The compiler is written in C++.`}
            githubUrl="https://github.com/gboduljak/stanford-compilers-coursework"
          />
          <ProjectCard 
            title="calc" 
            description={`calc is a simple console calculator written in Haskell.`}
            githubUrl="https://github.com/gboduljak/calc"/>
        </div>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>      
            <Link to={'/'} rel='prev'>
              ← {'Home'}
            </Link>
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default ProjectsPage