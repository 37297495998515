import React from "react"

const projectCardStyle = {
    cardPassiveStyle : {
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        borderRadius: "5px",
        padding: "2px 32px",
        margin: "10px"
    }
};

const ProjectCard = ({title, description, githubUrl}) => {
    return (
        <div style={projectCardStyle.cardPassiveStyle } >
            <div>
                <h2><b>{title}</b></h2>
                <p>
                    {description} <br/>
                    <a href={githubUrl} target="_blank" rel="noreferrer">Github →</a>
                </p>
            </div>
        </div>
    )
}
export default ProjectCard